<template>
  <v-container fluid px-0>
    <v-row row wrap>
      <v-flex xs10 sm6 offset-sm3 mt-3 mx-auto>
        <div class="overflow-y-auto">
          <login-form />
        </div>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    LoginForm: () =>
      import(/* webpackChunkName: "LoginForm" */ '@/components/Login/LoginForm')
  },
  head: {
    title() {
      return {
        inner: 'Logi sisse - Piletivahetus.ee'
      }
    },
    meta: [
      {
        name: 'description',
        content:
          'Kas Sa jäid pileti ostamisega hiljaks? Või just sooviksid oma teatri, kontserti pileti edasi müüa? Piletivahetus aitab!',
        id: 'desc'
      }
    ]
  }
}
</script>
